<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'media-index' }" />

    <template v-if="media">
      <h2 class="mb-4">{{ media.name }}</h2>

      <v-sheet elevation="1" class="pa-3">
        <v-row class="props-list">
          <v-col md="2">Название</v-col>
          <v-col md="10">{{ media.name }}</v-col>

          <v-col md="2">RSS</v-col>
          <v-col md="10">
            <v-text-field v-model="media.rss" dense />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <v-row>
          <v-col cols="12" sm="6" lg="3" xl="3">
            <media-logo v-model="logo" :media="media" />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="text-right">
          <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
        </div>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">СМИ по названию "{{ name }}" не найдено</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { encodeFileToBase64 } from "~/core/helpers";
import { UpdateMediaDto } from "~/libs/media/update-media-dto";

import { editorFields } from "~/mixins/multi-editor/editor-fields";

import MediaLogo from "~/components/media/logo";

export default {
  mixins: [editorFields],
  data() {
    return {
      media: null,
      logo: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchMedia();
  },
  computed: {
    name() {
      return this.$route.params.name;
    }
  },
  watch: {
    media() {
      this.logo = null;
    }
  },
  methods: {
    async fetchMedia() {
      this.loading = true;

      try {
        this.media = await this.$axios.$get(`news/rss/${this.name}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по названию: "${this.name}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async handleSave() {
      this.updating = true;

      try {
        const logoConverted = await encodeFileToBase64(this.logo);

        this.media = await this.$axios.$put(`news/rss/${this.name}`, new UpdateMediaDto(this.media, logoConverted));

        this.$snackbar.success("СМИ обновлена");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.media)).toHtml();

        this.$snackbar.error("Не удалось обновить СМИ: " + html);
      } finally {
        this.updating = false;
      }
    }
  },
  components: {
    MediaLogo
  }
};
</script>
